import api from '../utils/api';
import { setAlert } from './alert';
import {
  GET_VERSIONS,
  DOWNLOAD_ERROR
} from './types';


export const getVersions = () => async (dispatch) => {
  try {
    const res = await api.get('/download/versions');
    dispatch({
      type: GET_VERSIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: DOWNLOAD_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


