import {
    GET_VERSIONS,
    DOWNLOAD_ERROR
  } from '../actions/types';
  
  const initialState = {
    versions: {},
    loading: true,
    error: {}
  };
  
  function downloadReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_VERSIONS:
        return {
          ...state,
          versions: payload,
          loading: false
        };
      case DOWNLOAD_ERROR:
        return {
          ...state,
          error: payload,
          loading: false
        };
      default:
        return state;
    }
  }
  
  export default downloadReducer;
  