import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import formatDate from '../../utils/formatDate';
import { getPosts, deletePost, addPost, updatePost } from '../../actions/post';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import Spinner from '../layout/Spinner';


interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function generateKey() {
  const all = "2346789BCDFGHJKMPQRTVWXY";
  let text = ""
  for (let i = 0; i < 25; i++) {
    if (i && i % 5 === 0) text += '-'
    text += all[getRandomInt(24)]
  }
  return text
}


function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;
  let id = 0
  const handleClick = () => {
    setRows((oldRows) => {
      id = oldRows.length + 1
      return [{ id, idx: id, productKey: generateKey(), terminalsAllowed: 1, isNew: true, duration: 9999, edition: "Standard" }, ...oldRows]
    });
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'productKey' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}



const Dashboard = ({ deletePost, addPost, updatePost, getPosts, post: { posts, loading } }) => {
  useEffect(() => {
    getPosts();
  }, [getPosts]);


  useEffect(() => {
    setRows(posts.map((p, index) => ({ ...p, id: p._id, idx: posts.length - index, isNew: false, dateAdded: formatDate(p.dateAdded), dateActivated: formatDate(p.dateActivated), lastChecked: formatDate(p.lastChecked) })))
  }, [posts]);


  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    deletePost(id)
    setRows(rows.filter((row) => row['id'] !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row['id'] === id);
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row['id'] !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const payload = {
      productKey: newRow.productKey,
      terminalsAllowed: newRow.terminalsAllowed,
      duration: newRow.duration,
      edition: newRow.edition,
      activated: newRow.activated
    }
    if (newRow.isNew) {
      addPost(payload)
    } else {
      updatePost({ _id: newRow.id, ...payload})
    }
    const updatedRow = { ...newRow, isNew: false }
    setRows(rows.map((row) => (row['id'] === newRow['id'] ? updatedRow : row)))
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      type: 'number',

    },
    {
      field: 'idx',
      headerName: '#',
      type: 'number',
      width: 50,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'productKey',
      headerName: 'Key',
      width: 280,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'authKey',
      headerName: 'Auth',
      width: 100,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'businessName',
      headerName: 'Company',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'phone1',
      headerName: 'Phone Number',
      width: 120,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'versionNumber',
      headerName: 'Current Version',
      width: 80,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'dateAdded',
      headerName: 'Date Added',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'activated',
      headerName: 'Activated',
      type: 'boolean',
      width: 80,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'dateActivated',
      headerName: 'Date Activated',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'lastChecked',
      headerName: 'Date Last Checked',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'edition',
      headerName: 'Edition',
      width: 100,
      editable: true,
      type: 'singleSelect',
      valueOptions: ['Standard', 'Professional'],
    },
    {
      field: 'terminalsAllowed',
      headerName: 'Terminals',
      type: 'number',
      width: 80,
      editable: true,
    },
    {
      field: 'duration',
      headerName: 'Duration',
      type: 'number',
      width: 70,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  if (loading) return <Spinner/>
  return (
    <Box
      sx={{
        margin: "300px 0px 10px 100px",
        height: 1000,
        width: 1700,
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        columnVisibilityModel={{ id: false }}
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </Box>
  );
};



const mapStateToProps = (state) => ({
  post: state.post
});

interface StateProps {
  post: object
}

interface DispatchProps {
  getPosts: () => void,
  deletePost: () => void,
  updatePost: () => void,
  addPost: () => void
}

export default connect<StateProps, DispatchProps>(mapStateToProps, { getPosts, deletePost, addPost, updatePost })(Dashboard);
