import React from 'react';
import { Link } from 'react-router-dom';

const Landing = () => {
  return (
    <section className="landing">
      <div className="dark-overlay">
        <div className="landing-inner">
          <h1 className="x-large">Verve Software</h1>
          <p className="lead">
            Get free updates for Verve Software
          </p>
          <div className="buttons">
            <Link to="/downloads">
            <a className="btn btn-primary">
              Download
            </a>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};


export default Landing;
