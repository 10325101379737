import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addPost } from '../../actions/post';
import { useNavigate } from "react-router-dom";



function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function generateKey() {
  const all = "2346789BCDFGHJKMPQRTVWXY";
  let text = ""
  for (let i = 0; i < 25; i++) {
    if (i && i % 5 === 0) text += '-'
    text += all[getRandomInt(24)]
  }
  return text
}

const PostForm = ({ addPost }) => {
  let [text, setText] = useState(generateKey());
  let [number, setNumber] = useState(1);
  let [duration, setDuration] = useState(9999);
  let [edition, setEdition] = useState("Standard");
  let navigate = useNavigate(); 
  const onSubmit = e => {
    e.preventDefault();
    addPost({ text, number, duration, edition });
    navigate('/');
  }
  const onGenerate = e => {
    e.preventDefault();
    setText(generateKey)
  }
  return (
    <div className='post-form'>
      <form className='form my-1'>
        <div className="form-group">
          <input
            type="text"
            readOnly={true}
            placeholder="Clé"
            name="text"
            value={text}
            onChange={e => setText(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Nombre de machines"
            name="number"
            value={number}
            onChange={e => setNumber(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Nombre de jours"
            name="duration"
            value={duration}
            onChange={e => setDuration(e.target.value)}
          />
        </div>
        <select 
          class="form-select form-select-lg mb-3"
          aria-label=".form-select-lg example" 
          name="edition"
          value={edition}
          onChange={e => setEdition(e.target.value)}
        >
          <option value="Standard">Standard</option>
          <option value="Professional">Professionel</option>
        </select>
        <input type='submit' onClick={onSubmit} className='btn btn-dark my-1' value='Submit' />
        <input type='submit' onClick={onGenerate} className='btn btn-dark my-1' value='Generate' />
      </form>
    </div>
  );
};

PostForm.propTypes = {
  addPost: PropTypes.func.isRequired
};

export default connect(
  null,
  { addPost }
)(PostForm);
