import React, { useEffect } from 'react';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import Home from '@mui/icons-material/Home'
import api from '../../utils/api'
import { getVersions } from '../../actions/download';
import { useComponentWillMount } from '../../utils/customHooks';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';

function Downloads({ getVersions, download: { versions, loading } }) {
  useEffect(() => {
    getVersions()
  }, [])

  if (loading) return <Spinner />

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        marginY: '200px',
        gap: 2,
        flexWrap: 'wrap',
        '& > *': { minWidth: 0, flexBasis: 400 },
      }}
    >
      <div>
        <List
          size='lg'
          variant="outlined"
          sx={{
            borderRadius: 'sm',
          }}
        >
          <ListItem>

            <h4>Software Products</h4>

          </ListItem>
          <ListItem>
            <a href="https://verve.software/download/pos"><u>Verve POS {versions.pos}</u></a>
          </ListItem>
        </List>
        <List
          size='lg'
          variant="outlined"
          sx={{
            borderRadius: 'sm',
          }}
        >
          <ListItem>
            <h4>Dependencies</h4>
          </ListItem>
          <ListItem>
            <a href="https://download.visualstudio.microsoft.com/download/pr/bb581716-4cca-466e-9857-512e2371734b/5fe261422a7305171866fd7812d0976f/windowsdesktop-runtime-8.0.7-win-x64.exe">
            <u>.NET Desktop Runtime 8.0.7</u></a>
          </ListItem>
          <ListItem>
            <a href="https://cdn.mysql.com//Downloads/MySQL-8.4/mysql-8.4.3-winx64.msi"><u>MySQL Community Server 8.4</u></a>
          </ListItem>
          <ListItem>
            <a href="https://aka.ms/vs/17/release/vc_redist.x64.exe"><u>Microsoft Visual C++ Redistributable</u></a>
          </ListItem>
          <ListItem>
            <a href="https://github.com/rustdesk/rustdesk/releases/download/1.3.2/rustdesk-1.3.2-x86_64.msi"><u>RustDesk</u></a>
          </ListItem>
        </List>
        <List
          size='lg'
          variant="outlined"
          sx={{
            borderRadius: 'sm',
          }}
        >
          <ListItem>
            <h4>Drivers</h4>
          </ListItem>
          <ListItem>
            <a href="https://verve.software/download/xprinter_barcode_driver"><u>Xprinter Barcode Driver</u></a>
          </ListItem>
        </List>
      </div>
    </Box>
  );
}

Downloads.propTypes = {
  getVersions: PropTypes.func.isRequired,
  download: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  download: state.download
});

export default connect(
  mapStateToProps,
  { getVersions }
)(Downloads);